import { defineStore } from 'pinia';

export const useAuthStore = defineStore('auth', {
  state: () => {
    return {
      id: undefined,
      first_name: undefined,
      last_name: undefined,
      email: undefined,
      role: undefined,
      status: undefined,
      regions: undefined,
      groups: undefined,
      initiative_types: undefined,
      user_name: undefined,
      doe_id: undefined,
      last_logged_in: undefined,
      created_at: undefined,
      updated_at: undefined,
      created_by: undefined,
      updated_by: undefined,
    }
  },
  persist: true,
});
