<template>
  <div>
    <EppHeader
      heading="Events"
      subtitle="Manage events and registrations"
      icon="calendar_today"
      :iconOutlined="true"
    />
    <TabNavigation
      :items="tabs.map((tab) => ({ tab: tab.type + ' events' }))"
      class="tab-navigation"
    >
      <template
        #extra
        v-if="['Super Admin', 'Event Admin'].includes(loggedInUserRole)"
      >
        <a
          class="v-tab"
          href="https://education.nsw.gov.au/inside-the-department/skills-initiatives-and-programs/educational-pathways-program-/referral-information---program-initiatives"
          target="_blank"
        >Referrals</a>
      </template>
      <template
        v-for="(tab, i) in tabs"
        #[tab.content]
      >
        <v-container :key="i">
          <v-row>
            <v-col>
              <Alert
                showAlert
                type="success"
                :text="createSuccessMessage"
                inPage
                :backgroundColour="true"
                v-if="showEventCreationSuccessMessage"
                allowDismiss
              />
              <Alert
                showAlert
                type="success"
                :text="editSuccessMessage"
                inPage
                :backgroundColour="true"
                v-if="showEventUpdateSuccessMessage"
                allowDismiss
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div
                class="d-flex flex-row justify-space-between align-center"
                style="gap: 30px"
              >
                <div style="width: 100%">
                  <AdsTextField
                    v-model="search"
                    prepend-inner-icon="mdi-magnify"
                    background-color="white"
                    placeholder="Search"
                    label="Search"
                    single-line
                    hide-details
                    clearable
                    :outlined="true"
                  />
                </div>
                <div>
                  <AdsButton
                    v-if="tab.type == 'upcoming' &&
                      ['Super Admin', 'Event Admin'].includes(
                        loggedInUserRole
                      )
                    "
                    icon="add_circle_outline"
                    buttonText="Create event"
                    @click="toggleCreateDialog()"
                  />
                </div>
                <div>
                  <FullscreenDialog
                    v-if="showCreateDialog"
                    title="Create event"
                    v-model="showCreateDialog"
                    :showCloseButton="false"
                  >
                    <template #content>
                      <CreateEvent
                        v-if="showCreateDialog"
                        @eventAdded="toggleCreateDialog"
                      />
                    </template>
                  </FullscreenDialog>

                  <FullscreenDialog
                    v-if="showEditDialog"
                    :title="`${dulplicateEvent ? 'Duplicate' : 'Edit'} event`"
                    v-model="showEditDialog"
                    :showCloseButton="false"
                  >
                    <template #content>
                      <EditEvent
                        v-if="showEditDialog"
                        @eventEdited="toggleEditDialog(eventToEdit, false)"
                        @showEditSuccessMessage="showEditSuccess"
                        :event="eventToEdit"
                        :dulplicateEvent="dulplicateEvent"
                      />
                    </template>
                  </FullscreenDialog>

                  <FullscreenDialog
                    title="Add registrations"
                    v-model="showStudentRegistrationDialog"
                  >
                    <template #content>
                      <StudentRegistration
                        v-if="eventToRegister"
                        :event="eventToRegister"
                        @closeAndSave="toggleStudentRegistrationDialog"
                      />
                    </template>
                  </FullscreenDialog>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div class="d-flex align-center flex-wrap">
                <label class="mr-4 text-no-wrap">Filter by:</label>
                <template v-for="(filter, key) in filters">
                  <DateChipFilter
                    v-if="filter.type == 'date'"
                    v-model="selectedFilters[key]"
                    :key="key"
                    class="ml-1"
                    :name="filter.label"
                    :items="filter.items"
                    heading="Date"
                  />
                  <ChipFilter
                    v-if="filter.label !== 'Schools' && filter.type !== 'date'"
                    v-model="selectedFilters[key]"
                    :key="key"
                    class="chipFilter ml-1"
                    :name="filter.label"
                    :type="filter.type"
                    :items="filter.items"
                    :heading="filter.label"
                  />

                  <ChipFilter
                    v-if="filter.label == 'Schools'"
                    v-model="selectedFilters[key]"
                    type="checkbox"
                    :items="transformedSchoolData"
                    :multi-level="true"
                    heading="Schools"
                    name="Schools"
                    :key="key"
                  />
                </template>
                <AdsButton
                  buttonText="Clear all"
                  class="clearAll"
                  tertiary
                  @click="clearAll"
                />
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="10" />
            <v-col cols="2">
              <div class="d-flex align-center justify-end">
                <label class="mr-4 text-no-wrap">Sort by:</label>
                <AdsSelect
                  v-model="selectedSort"
                  :items="sortOptions"
                  item-text="name"
                  item-value="id"
                  dense
                  hide-details
                  background-color="white"
                  :return-object="true"
                />
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <div v-if="loading">
                Loading events...
              </div>
              <div
                v-else-if="grouped_events[tab.type] &&
                  grouped_events[tab.type].length === 0
                "
              >
                There are no {{ tab.type }} events to display.
              </div>
              <div v-else>
                <div
                  v-for="event in grouped_events[tab.type]"
                  :key="event.id"
                  class="mb-6"
                >
                  <EventCard
                    :event="event"
                    :schools="schools"
                    @editDialogToggled="toggleEditDialog"
                    @openStudentRegistration="toggleStudentRegistrationDialog"
                    @eventCancelled="fetchEvents"
                  />
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </TabNavigation>
  </div>
</template>

<script>
import {
  ADS_Colors,
  AdsButton,
  AdsTextField,
  ChipFilter,
  FullscreenDialog,
  AdsSelect,
  Alert,
} from '@nswdoe/doe-ui-core';

import { useAuthStore } from '@/stores/auth';
import { useInitiativeTypesStore } from '@/stores/initiativeTypes';
import { useCategoriesStore } from '@/stores/categories';
import { useActivityTypesStore } from '@/stores/activityTypes';
import { useTermsStore } from '@/stores/terms';
import { useFormatsStore } from '@/stores/formats';
import { useAudiencesStore } from '@/stores/audiences';
import { usePresenterTypesStore } from '@/stores/presenterTypes';

import DateChipFilter from '@/components/DateChipFilter';
import TabNavigation from '@/components/TabNavigation';
import EventCard from '@/components/EventCard';
import CreateEvent from '@/components/CreateEvent';
import EditEvent from '@/components/EditEvent';
import StudentRegistration from '@/components/StudentRegistration';

export default {
  name: 'EppEvents',
  title: 'EPP Initiative Management Application - Events',
  components: {
    AdsButton,
    AdsSelect,
    AdsTextField,
    ChipFilter,
    DateChipFilter,
    FullscreenDialog,
    TabNavigation,
    EventCard,
    CreateEvent,
    EditEvent,
    StudentRegistration,
    Alert,
  },
  data() {
    return {
      ADS_Colors,
      selectedSort: { id: 'date', name: 'Date' },
      sortOptions: [
        { id: 'date', name: 'Date' },
        { id: 'alphabetically', name: 'Alphabetically' },
      ],
      showEventCreationSuccessMessage: false,
      showEventUpdateSuccessMessage: false,
      selectedFilters: {
        initiative_types: [],
        category: [],
        activity_type: [],
        schools: [],
        date: null,
        term: [],
        format: [],
        audiences: [],
        vet_combined: [],
      },
      search: '',
      showCreateDialog: false,
      showEditDialog: false,
      showStudentRegistrationDialog: false,
      eventToRegister: {},
      events: [],
      schools: [],
      transformedSchoolData: [],
      regions: [],
      schoolGroups: [],
      eventToEdit: {},
      dulplicateEvent: false,
      loading: false,
      eventTitle: '',
      changed: false,
    };
  },
  computed: {
    createSuccessMessage() {
      return `Your event ${this.eventTitle} has been successfully created`;
    },
    initiativeTypes() {
      const initiativeTypesStore = useInitiativeTypesStore();
      return initiativeTypesStore.items;
    },
    categories() {
      const categories = useCategoriesStore();
      return categories.items;
    },
    activityTypes() {
      const activityTypes = useActivityTypesStore();
      return activityTypes.items;
    },
    terms() {
      const terms = useTermsStore();
      return terms.items;
    },
    formats() {
      const formats = useFormatsStore();
      return formats.items;
    },
    audiences() {
      const audiences = useAudiencesStore();
      return audiences.items;
    },
    presenterTypes() {
      const presenterTypes = usePresenterTypesStore();
      return presenterTypes.items;
    },
    loggedInUserRole() {
      const auth = useAuthStore();
      return auth.role;
    },
    tabs() {
      if (!this.loggedInUserRole) {
        return [];
      }
      const tabs = [
        { type: 'upcoming' },
        { type: 'past' },
        { type: 'cancelled' },
      ];
      tabs.forEach((tab, i) => {
        tab.content = 'content' + (i + 1).toString();
      });
      return tabs;
    },
    filters() {
      const filters = {
        initiative_types: {
          label: 'Initiative types',
          items: this.initiativeTypes,
        },
        category: {
          label: 'Event category',
          items: this.categories,
        },
        vet_combined: {
          label: 'VA combined',
          items: ['Yes', 'No'],
        },
        activity_type: {
          label: 'Activity type',
          items: this.activityTypes,
        },
        schools: {
          label: 'Schools',
          items: this.schools,
        },
        date: {
          label: 'Date',
          type: 'date',
        },
        term: {
          label: 'Term',
          items: this.terms,
        },
        format: {
          label: 'Format',
          items: this.formats,
        },
        audiences: {
          label: 'Audiences',
          items: this.audiences,
        },
      };
      Object.values(filters).forEach((filter) => {
        if (filter.type != 'date') {
          filter.items = filter.items.map((name) => ({
            id: name,
            name,
          }));
        }
        if (!filter.type) {
          filter.type = 'checkbox';
        }
      });

      return filters;
    },
    grouped_events() {
      // Function to group events based on their status, and filter them according to search query and selected filters
      // Object to hold grouped events
      const tabEvents = {
        upcoming: [],
        past: [],
        cancelled: [],
      };

      // Get current date and set time to midnight
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      // Iterate through all events
      this.events.forEach((event) => {
        // If there's a search query, perform a search
        if (this.search) {
          // Convert search query to lowercase
          const searchQuery = this.search.toLowerCase();
          let foundMatch = false;

          // Iterate through event keys to find matches
          for (const key in event) {
            const value = event[key];
            // If value is a string and includes search query, mark as found
            if (
              typeof value === 'string' &&
              value.toLowerCase().includes(searchQuery)
            ) {
              foundMatch = true;
              break;
            } else if (Array.isArray(value)) {
              // If value is an array, search within array for a match
              if (
                value.some(
                  (item) =>
                    typeof item === 'string' &&
                    item.toLowerCase().includes(searchQuery)
                )
              ) {
                foundMatch = true;
                break;
              }
            }
          }

          // Skip this event if it doesn't match the search query
          if (!foundMatch) return;
        }

        // Check date filter
        if (this.selectedFilters.date) {
          // Convert event start and end dates to Date objects, and set time to midnight
          const eventStartDate = new Date(event.start_datetime);
          const eventEndDate = new Date(event.end_datetime);
          eventStartDate.setHours(0, 0, 0, 0);
          eventEndDate.setHours(0, 0, 0, 0);

          // Check if selected date filter includes a date range
          if (this.selectedFilters.date.includes(' - ')) {
            // Handle date range by splitting the start and end dates
            const [startDateFilter, endDateFilter] = this.selectedFilters.date
              .split(' - ')
              .map((dateStr) => new Date(dateStr));
            startDateFilter.setHours(0, 0, 0, 0);
            endDateFilter.setHours(0, 0, 0, 0);

            // Check if the event falls outside the selected date range
            if (
              eventEndDate < startDateFilter ||
              eventStartDate > endDateFilter
            )
              return;
          } else {
            // Handle single date
            const dateFilter = new Date(this.selectedFilters.date);
            dateFilter.setHours(0, 0, 0, 0);

            // Check if the selected date falls outside the event's date range
            if (dateFilter < eventStartDate || dateFilter > eventEndDate)
              return;
          }
        }

        // Apply other filters (initiative types, category, activity type, schools, term, format, audiences)
        if (
          this.selectedFilters.initiative_types.length &&
          !this.selectedFilters.initiative_types.includes(event.initiative_type)
        )
          return;
        if (
          this.selectedFilters.category.length &&
          !this.selectedFilters.category.includes(event.category)
        )
          return;
        if (
          this.selectedFilters.vet_combined.length > 0 &&
          (this.selectedFilters.vet_combined[0] === 'Yes' ? true : false) !==
          event.combined_event
        )
          return;
        if (
          this.selectedFilters.activity_type.length &&
          !this.selectedFilters.activity_type.includes(event.activity_type)
        )
          return;
        if (
          this.selectedFilters.schools.length &&
          !event.schools.some((school) =>
            this.selectedFilters.schools.includes(school)
          )
        )
          return;
        if (
          this.selectedFilters.term.length &&
          !this.selectedFilters.term.includes(event.term)
        )
          return;
        if (
          this.selectedFilters.format.length &&
          !this.selectedFilters.format.includes(event.format)
        )
          return;
        if (
          this.selectedFilters.audiences.length &&
          !event.audiences.some((audience) =>
            this.selectedFilters.audiences.includes(audience)
          )
        )
          return;

        // Create date_string field for single or multi-day events
        // This will format the date and time in a human-readable way
        const startDate = new Date(event.start_datetime);
        const endDate = new Date(event.end_datetime);

        const optionsDate = {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
        };
        const optionsTime = {
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
        };

        const formattedStartDate = startDate.toLocaleDateString(
          'en-AU',
          optionsDate
        );
        const formattedEndDate = endDate.toLocaleDateString(
          'en-AU',
          optionsDate
        );
        const formattedStartTime = startDate.toLocaleTimeString(
          'en-AU',
          optionsTime
        );
        const formattedEndTime = endDate.toLocaleTimeString(
          'en-AU',
          optionsTime
        );

        // If start and end dates are the same, combine them into one string; otherwise, create a range
        if (formattedStartDate === formattedEndDate) {
          event.date_string = `${formattedStartDate} ${formattedStartTime} - ${formattedEndTime}`;
        } else {
          event.date_string = `${formattedStartDate} ${formattedStartTime} - ${formattedEndDate} ${formattedEndTime}`;
        }

        // Categorize events based on date and status (cancelled, upcoming, or past)
        if (event.status === 'cancelled') {
          tabEvents.cancelled.push(event);
        } else if (endDate >= currentDate) {
          tabEvents.upcoming.push(event);
        } else if (startDate < currentDate && endDate < currentDate) {
          tabEvents.past.push(event);
        }
      });

      tabEvents.upcoming.sort(this.getSortFunction('upcoming'));
      tabEvents.past.sort(this.getSortFunction('past'));
      tabEvents.cancelled.sort(this.getSortFunction('past'));

      // Return the grouped and filtered events
      return tabEvents;
    },
  },
  mounted() {
    this.fetchEvents();

    Promise.all([
      this.$api.schools.fetchNestedData().then((results) => {
        this.schools = results;
        this.transformedSchoolData = this.transformSchoolData(results);
      }),
    ]).catch(() => { });
  },
  methods: {
    transformSchoolData(data) {
      return data
        .map((item) => {
          const newItem = { ...item };
          delete newItem.region_id;
          delete newItem.last_updated;
          delete newItem.updated_by;
          delete newItem.school_group_id;
          delete newItem.category;
          if (newItem.groups) {
            newItem.children = this.transformSchoolData(newItem.groups);
            delete newItem.groups;
          }
          if (newItem.schools) {
            newItem.children = newItem.schools.map((school) => {
              const newSchool = { ...school };
              delete newSchool.region_id;
              delete newSchool.last_updated;
              delete newSchool.updated_by;
              delete newSchool.school_group_id;
              delete newSchool.category;
              return newSchool;
            });
            delete newItem.schools;
          }
          return newItem;
        })
        .filter((item) => item.children && item.children.length > 0);
    },
    fetchEvents() {
      this.loading = true;
      this.$api.events.fetch().then((response) => {
        this.events = response.events;
        this.loading = false;
      });
    },
    sortByDateAscending(a, b) {
      return new Date(a.start_datetime) - new Date(b.start_datetime);
    },
    sortByDateDescending(a, b) {
      return new Date(b.start_datetime) - new Date(a.start_datetime);
    },
    sortByNameAscending(a, b) {
      const nameA = a.title.toUpperCase();
      const nameB = b.title.toUpperCase();
      return nameA.localeCompare(nameB);
    },
    sortByDateThenNameAscending(a, b) {
      const dateComparison = this.sortByDateAscending(a, b);
      if (dateComparison !== 0) return dateComparison;
      return this.sortByNameAscending(a, b);
    },
    sortPastEvents(a, b) {
      const dateComparison = this.sortByDateDescending(a, b);
      if (dateComparison !== 0) return dateComparison;
      return this.sortByNameAscending(a, b);
    },
    getSortFunction(eventType) {
      if (this.selectedSort.id === 'date') {
        if (eventType === 'upcoming') {
          return this.sortByDateThenNameAscending;
        } else if (eventType === 'past') {
          return this.sortPastEvents;
        }
      } else if (this.selectedSort.id === 'alphabetically') {
        return this.sortByNameAscending;
      }
    },
    toggleCreateDialog(data) {
      this.eventTitle = data?.title;
      this.showCreateDialog = !this.showCreateDialog;
      if (!this.showCreateDialog) {
        this.fetchEvents();
        if (data?.eventCreated) {
          this.showEventCreationSuccessMessage = true;
          setTimeout(() => {
            this.showEventCreationSuccessMessage = false;
          }, 3000); // 3000 milliseconds = 3 seconds
        }
      }
    },
    showEditSuccess(message) {
      this.showEventUpdateSuccessMessage = true;
      this.editSuccessMessage = message;
      setTimeout(() => {
        this.showEventUpdateSuccessMessage = false;
      }, 3000); // 3000 milliseconds = 3 seconds
    },
    toggleEditDialog(event, duplicateFlag = false, changed = false) {
      this.changed = changed;
      this.eventTitle = this.eventToEdit.title;
      this.eventToEdit = {};
      this.eventToEdit = event;
      this.dulplicateEvent = duplicateFlag;
      this.showEditDialog = !this.showEditDialog;
      if (!this.showEditDialog) {
        this.fetchEvents();
      }
    },
    toggleStudentRegistrationDialog(event) {
      this.eventToRegister = event;
      this.showStudentRegistrationDialog = !this.showStudentRegistrationDialog;
      if (!this.showStudentRegistrationDialog) {
        this.fetchEvents();
      }
    },
    clearAll() {
      for (const [key, filter] of Object.entries(this.filters)) {
        if (!this.selectedFilters[key]) {
          continue;
        }
        this.selectedFilters[key] = filter.type == 'checkbox' ? [] : undefined;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.chipFilter:deep(.v-card__actions li) {
  padding-right: 20px;
}

.tab-navigation:deep {
  .v-tabs-items {
    background-color: transparent;
  }

  >.v-window {
    /* Allow space for the open filters */
    min-height: 550px;

    .v-menu__content {
      max-height: 450px !important;
    }
  }
}

.v-expansion-panels {
  border-radius: 0 0 4px 4px;

  &.wideActions .v-data-table:deep(td:last-child) {
    min-width: 134px;
  }
}

.v-data-table {
  .v-chip {
    width: 145px;
    height: 23px;
  }

  &:deep {
    th {
      font-size: 14px !important;
    }

    th,
    td:first-child,
    /* Date */
    td:nth-child(2) {
      /* Time */
      white-space: nowrap;
    }

    td:nth-child(3) {
      word-break: break-word;
    }

    th span {
      display: inline-block;
      white-space: pre-wrap;
    }
  }
}
</style>
