<template>
  <div>
    <EppHeader
      :heading="event.title"
      icon="event"
      :iconOutlined="true"
    >
      <template slot="subtitle">
        <div class="pl-3">
          <div>
            <v-icon
              class="mr-2 mb-1"
              small
            >
              av_timer
            </v-icon>{{ timeRange }}
          </div>
          <div class="mt-1">
            <v-icon
              class="mr-2 mb-1 material-icons-outlined"
              small
            >
              calendar_today
            </v-icon>{{ dateRange }}
            <a
              :href="icsFileUrl"
              download="event.ics"
              class="ml-4 d-print-none"
            >Add to calendar</a>
          </div>
          <div class="mt-1">
            <v-icon
              class="mr-2 mb-1 material-icons-outlined"
              small
            >
              room
            </v-icon><span v-if="event.format == 'Online'">{{ event.access_link }}
              <a
                :href="event.access_link"
                target="_blank"
                class="ml-4 d-print-none"
              >
                Join</a>
            </span>
            <span v-if="event.format == 'In-person'">{{ event.venue_address }}
              <a
                :href="'https://www.google.com/maps/search/' +
                  encodeURIComponent(event.venue_address)
                "
                target="_blank"
                class="ml-4 d-print-none"
              >
                View map</a>
            </span>
          </div>
        </div>
      </template>
      <template slot="rightPannel">
        <div class="d-flex">
          <div
            class="square"
            v-for="(card, key) in cards"
            :key="key"
          >
            <div class="num-container">
              <span class="num-large">{{ card.number }}</span>
              <span class="separator">/</span>
              <span class="num-small">{{ card.total }}</span>
            </div>
            <div class="text-container">
              {{ card.label }}
            </div>
          </div>
        </div>
      </template>
    </EppHeader>
    <v-container v-if="!otherAttendeeForm">
      <v-row>
        <v-col cols="12">
          <div class="d-flex justify-space-between mb-8 flex-column flex-md-row d-print-none">
            <div>
              <h2>1. Student attendance</h2>
            </div>
            <div class="d-flex flex-column flex-md-row mt-2 mt-md-0">
              <AdsButton
                v-if="selected.length > 0"
                class="ml-md-2 mt-2 mt-md-0"
                icon="check"
                buttonText="Mark attended"
                @click="markAttended()"
                :secondary="true"
              />
              <AdsButton
                v-if="selected.length > 0"
                class="ml-md-2 mt-2 mt-md-0"
                icon="close"
                buttonText="Mark unattended"
                @click="markUnttended()"
                :secondary="true"
              />
              <AdsButton
                class="ml-md-2 mt-2 mt-md-0"
                icon="person_add"
                buttonText="Add registrants"
                @click="toggleStudentRegistrationDialog()"
              />
              <FullscreenDialog
                title="Add registrants"
                v-model="showStudentRegistrationDialog"
              >
                <template #content>
                  <StudentRegistration
                    v-if="event"
                    :event="event"
                    @closeAndSave="toggleStudentRegistrationDialog"
                    ref="studentRegistration"
                  />
                </template>
              </FullscreenDialog>
            </div>
          </div>

          <AdsTextField
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            class="py-4 px-6 search-above-table"
            placeholder="Search"
            label="Search"
            single-line
            hide-details
            clearable
            :outlined="false"
          />

          <v-data-table
            v-model="selected"
            :class="{ 'empty-table': pageCount == 0 }"
            :headers="headers"
            :items="people"
            :search="search"
            :show-select="pageCount != 0"
            no-data-text="There are currently no registrations for this event"
            sort-by="name"
            sort-asc
          >
            <template #[`header.actions`] />
            <template #[`item.type`]="{ item }">
              <div style="text-transform: capitalize">
                {{ item.type }}
              </div>
            </template>
            <template #[`item.scholasticYearCode`]="{ item }">
              Year {{ item.scholasticYearCode }}
            </template>
            <template #[`item.created_at`]="{ item }">
              {{ formatDate(item.created_at) }}
            </template>
            <template #[`item.status`]="{ item }">
              <Chip
                :text="item.status"
                :color="statusColour(item.status)"
                style="text-transform: uppercase"
                small
              />
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <AdsButton
            icon="arrow_forward"
            buttonText="Add other attendees"
            @click="toggleOtherAttendeeForm"
            class="d-print-none"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="otherAttendeeForm">
      <v-row>
        <v-col>
          <h2 class="mb-1">
            2. Other attendees
          </h2>
          <p class="mb-2">
            <span v-if="event.requires_registration">Please enter 0 if no attendees of a type attended</span>
            <span v-if="!event.requires_registration">Please enter 1 or more for at least one type, and 0 or more for
              the
              others</span>
          </p>
        </v-col>
      </v-row>
      <v-row v-if="otherDataError">
        <v-col>
          <Alert
            showAlert
            type="error"
            :text="otherDataErrorMessage"
            inPage
            :backgroundColour="true"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-form ref="form">
            <v-row>
              <v-col>
                <AdsTextField
                  label="Number of parents/carers *"
                  v-model="event.parents_carers"
                  :rules="registrationRules"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <AdsTextField
                  label="Number of school staff *"
                  v-model="event.school_staff"
                  :rules="registrationRules"
                  hint="School staff includes Career advisors, Principals, Teaching staff and Transition Advisors"
                  persistent-hint
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <AdsTextField
                  label="Number of others *"
                  v-model="event.others"
                  :rules="registrationRules"
                  hint="Other is any other attendees besides Parents/Carers and School staff"
                  persistent-hint
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <AdsButton
                  icon="arrow_back"
                  buttonText="Previous"
                  @click="toggleOtherAttendeeForm"
                  class="d-print-none mr-2 mt-4"
                  v-if="event.requires_registration"
                />
                <AdsButton
                  buttonText="Submit"
                  @click="saveOtherData"
                  class="d-print-none mt-4"
                />
                <AdsButton
                  secondary
                  buttonText="Cancel"
                  @click="cancel"
                  class="d-print-none mt-4"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { useAuthStore } from '@/stores/auth';
import { parseISO, format, isValid } from 'date-fns';
import {
  ADS_Colors,
  AdsButton,
  Alert,
  AdsTextField,
  Chip,
  FullscreenDialog,
} from '@nswdoe/doe-ui-core';
import StudentRegistration from '@/components/StudentRegistration';

export default {
  name: 'EppEventDetail',
  title: 'EPP Initiative Management Application - Event Detail',
  components: {
    AdsButton,
    Alert,
    AdsTextField,
    Chip,
    StudentRegistration,
    FullscreenDialog,
  },
  data() {
    return {
      otherDataError: false,
      showStudentRegistrationDialog: false,
      backend: process.env.VUE_APP_API_BASE_URL,
      people: [],
      ADS_Colors,
      event: {},
      search: '',
      selected: [],
      pageCount: 0,
      otherAttendeeForm: false,
      icsFileUrl: '',
      rules: {
        isOneOrMore(value) {
          if (value !== null && value !== undefined) {
            return typeof value === 'number' && value >= 1;
          }
          return 'This field is required and must be a number greater than or equal to 1';
        },
        isZeroOrMore(value) {
          if (value !== null && value !== undefined) {
            return typeof value === 'number' && value >= 0;
          }
          return 'This field is required and must be a number greater than or equal to 0';
        },
        isValidNumber(value) {
          if (value !== null && value !== undefined) {
            return typeof value === 'number' && value >= 0;
          }
          return 'This field is required and must be a number';
        },
      },
      headers: [
        {
          text: 'Given Name',
          value: 'givenName',
        },
        {
          text: 'Family Name',
          value: 'familyName',
        },
        {
          text: 'Type',
          value: 'type',
        },
        {
          text: 'SRN',
          value: 'studentRegistrationNumber',
        },
        {
          text: 'School',
          value: 'schoolName',
        },
        {
          text: 'Year',
          value: 'scholasticYearCode',
        },
        {
          text: 'Registered',
          value: 'created_at',
        },
        {
          text: 'Status',
          value: 'status',
        },
        {
          value: 'actions',
          sortable: false,
        },
      ],
    };
  },
  computed: {
    loggedInUserRole() {
      const auth = useAuthStore();
      return auth.role;
    },
    admin() {
      return ['Super Admin', 'Event Admin', 'Student Admin'].includes(this.loggedInUserRole);
    },
    timeRange() {
      if (!this.event.start_datetime || !this.event.end_datetime) {
        return '';
      }

      try {
        const startTime = format(parseISO(this.event.start_datetime), 'h:mm a');
        const endTime = format(parseISO(this.event.end_datetime), 'h:mm a');
        return `${startTime} - ${endTime}`;
      } catch (error) {
        return '';
      }
    },
    dateRange() {
      if (!this.event.start_datetime || !this.event.end_datetime) {
        return '';
      }

      try {
        const startDate = format(parseISO(this.event.start_datetime), 'EEE d MMM, yyyy');
        const endDate = format(parseISO(this.event.end_datetime), 'EEE d MMM, yyyy');

        let dateRange;
        if (startDate === endDate) {
          dateRange = startDate;
        } else {
          dateRange = `${startDate} - ${endDate}`;
        }

        return dateRange;
      } catch (error) {
        return '';
      }
    },
    cards() {
      return [
        {
          number: this.event.number_of_registrations,
          total: this.event.capacity,
          label: 'Registrations',
        },
        {
          number: this.event.number_of_attendees,
          total: this.event.number_of_registrations,
          label: 'Attended',
        },
      ];
    },
    registrationRules() {
      return this.event.requires_registration
        ? [this.rules.isZeroOrMore]
        : [this.rules.isValidNumber];
    },
    otherDataErrorMessage() {
      if (this.event.requires_registration) {
        return 'All fields must have a value of 0 or more';
      }
      return 'At least one field must have a value of 1 or more, the others must have a value of 0 or more';
    },
  },
  mounted() {
    this.fetchEventData(this.$route.params.id);
    this.fetchRegistrants(this.$route.params.id);
  },
  methods: {
    statusColour(status) {
      let colour;
      if (status === 'registered') {
        colour = '#407EC9';
      }
      if (status === 'attended') {
        colour = '#071E42';
      }
      if (status === 'unattended') {
        colour = '#94a3b8';
      }

      return colour;
    },
    saveOtherData() {
      if (this.event.requires_registration) {
        if (
          this.isNumberGreaterOrEqualThanZero(this.event.parents_carers) &&
          this.isNumberGreaterOrEqualThanZero(this.event.school_staff) &&
          this.isNumberGreaterOrEqualThanZero(this.event.others)
        ) {
          this.submitOtherData();
        } else {
          this.otherDataError = true;
        }
      } else {
        if (
          this.isValidNumber(this.event.parents_carers) &&
          this.isValidNumber(this.event.school_staff) &&
          this.isValidNumber(this.event.others) &&
          (this.event.parents_carers >= 1 ||
            this.event.school_staff >= 1 ||
            this.event.others >= 1)
        ) {
          this.submitOtherData();
        } else {
          this.otherDataError = true;
        }
      }
    },
    submitOtherData() {
      this.$api.events.saveOtherAttendeeData(this.$route.params.id, {
        parents_carers: this.event.parents_carers,
        school_staff: this.event.school_staff,
        others: this.event.others,
      }).then(() => {
        this.fetchEventData(this.$route.params.id);
        this.fetchRegistrants(this.$route.params.id);
        this.toggleOtherAttendeeForm();
        this.$router.push('/');
      });
    },
    cancel() {
      this.$router.push('/');
    },
    toggleOtherAttendeeForm() {
      this.otherAttendeeForm = !this.otherAttendeeForm;
    },
    toggleStudentRegistrationDialog() {
      this.showStudentRegistrationDialog = !this.showStudentRegistrationDialog;
      if (!this.showStudentRegistrationDialog) {
        this.fetchEventData(this.$route.params.id);
        this.fetchRegistrants(this.$route.params.id);
      }
      this.$refs.studentRegistration.fetchData();
    },
    withdraw() {
      this.$api.registrants.withdraw(this.selected).then(() => {
        this.fetchEventData(this.$route.params.id);
        this.fetchRegistrants(this.$route.params.id);
      });
    },
    markAttended() {
      this.$api.registrants.markAttendance(this.selected, 'attended').then(() => {
        this.fetchEventData(this.$route.params.id);
        this.fetchRegistrants(this.$route.params.id);
        this.selected = [];
      });
    },
    markUnttended() {
      this.$api.registrants.markAttendance(this.selected, 'unattended').then(() => {
        this.fetchEventData(this.$route.params.id);
        this.fetchRegistrants(this.$route.params.id);
        this.selected = [];
      });
    },
    formatDate(timestamp) {
      const date = new Date(timestamp);
      return format(date, 'dd/MM/yyyy');
    },
    goHome() {
      this.$router.push({ name: 'events' });
    },
    async fetchEventData(id) {
      try {
        const response = await this.$api.events.fetch(id);
        this.event = response.events[0];
        this.validateEventDates();
      } catch (error) {
        // console.error('Error fetching event data:', error);
      }
    },
    fetchRegistrants(id) {
      this.$api.registrants.getExistingRegistrations(id).then((response) => {
        this.people = response.registrants.sort((a, b) => {
          if (a.familyName < b.familyName) {
            return -1;
          }
          if (a.familyName > b.familyName) {
            return 1;
          }
          return 0;
        });
        this.pageCount = Math.ceil(this.people.length / 10);
      });
    },
    validateEventDates() {

      if (!this.event.start_datetime || !this.event.end_datetime) {
        return;
      }

      if (!isValid(parseISO(this.event.start_datetime)) || !isValid(parseISO(this.event.end_datetime))) {
        return;
      }

      this.generateICSFile();
    },
    generateICSFile() {
      const start = this.formatDateForICS(this.event.start_datetime);
      const end = this.formatDateForICS(this.event.end_datetime);

      if (!start || !end) {
        return;
      }

      const title = this.event.title || 'Event';
      const description = this.event.description || '';
      const location = this.event.format === 'Online' ? this.event.access_link : this.event.venue_address;

      const icsContent = `
BEGIN:VCALENDAR
VERSION:2.0
BEGIN:VEVENT
SUMMARY:${title}
DESCRIPTION:${description}
DTSTART:${start}
DTEND:${end}
LOCATION:${location}
END:VEVENT
END:VCALENDAR
      `.trim().replace(/\n/g, '\r\n');  // Ensure proper line breaks


      const blob = new Blob([icsContent], { type: 'text/calendar' });
      this.icsFileUrl = URL.createObjectURL(blob);
    },
    formatDateForICS(dateString) {
      if (!dateString) {
        return null;
      }
      const parsedDate = parseISO(dateString);
      if (!isValid(parsedDate)) {
        return null;
      }
      return format(parsedDate, "yyyyMMdd'T'HHmmss'Z'");
    },
    isNumberGreaterOrEqualThanZero(value) {
      return value !== null && value !== undefined && !isNaN(value) && parseFloat(value) >= 0;
    },
    isNumberGreaterThanZero(value) {
      return value !== null && value !== undefined && !isNaN(value) && parseFloat(value) >= 1;
    },
    isValidNumber(value) {
      return value !== null && value !== undefined && !isNaN(value) && parseFloat(value) >= 0;
    },
  },
  watch: {
    '$route.params.id': function (newId, oldId) {
      if (newId !== oldId) {
        this.fetchEventData(newId);
      }
    },
    'event.requires_registration'(newStatus) {
      if (!newStatus) {
        this.otherAttendeeForm = true;
      } else {
        this.otherAttendeeForm = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.square {
  background: rgba(64, 126, 201, 0.3);
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 162px;
  height: 128px;
  margin-left: 10px;
  border-radius: 5px;
}

.num-container {
  display: flex;
  align-items: baseline;
}

.num-large {
  font-size: 40px;
}

.separator {
  font-size: 14px;
}

.num-small {
  font-size: 14px;
}

.text-container {
  font-size: 14px;
  color: rgba(108, 172, 228, 1);
  font-weight: bold;
}
</style>
