<template>
  <v-app :class="{ 'mobile': $vuetify.breakpoint.xsOnly }">
    <NavigationDrawer
      class="d-print-none"
      :items="navDrawerItems"
      v-if="showNavSidebar"
    />

    <Notifications
      ref="notifications"
      :notificationData="notificationsData"
    />

    <AppBar
      class="d-print-none"
      color="primary"
      appName="my-nsw-gov"
      title="EPP Initiative Management Application"
      :showNavIcon="true"
      :showNotifications="true"
      :newNotifications="newNotifications"
      :enableHomeLink="false"
    >
      <template #appIcon>
        <AppIcon
          appName="my-nsw-gov"
          :transparent="true"
          size="50px"
          class="mt-2"
        />
      </template>

      <template #myEssentials>
        <MyEssentials
          v-if="essentialsData"
          :essentialsData="essentialsData"
          title="Recently used"
          essentialText="Go to My Essentials"
          :essentialUrl="essentialUrl"
        />
      </template>

      <template #profile>
        <Profile
          :givenName="profile.first_name"
          :surname="profile.last_name"
          :jobTitle="loggedInUserRole"
          :portalSettings="false"
          :updateProfileHandler="updateProfileHandler"
          :changePasswordHandler="changePasswordHandler"
          :updateSecretQuestionHandler="updateSecretQuestionHandler"
          :logoutHandler="logoutHandler"
        />
      </template>
    </AppBar>

    <v-main>
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
    </v-main>

    <!-- App-wide component -->
    <BlockUI v-if="!loggedInUserRole && !loginFailed" />

    <ConfirmationDialog :data="confirmationDialog" />
  </v-app>
</template>

<script>
import Vue from 'vue';

import { AppBar, NavigationDrawer, Profile, MyEssentials, AppIcon, BlockUI } from '@nswdoe/doe-ui-core';

import ConfirmationDialog from '@/components/ConfirmationDialog';
import Notifications from '@/components/Notifications';

import { setupHttp } from '@/api/http';

import { useAuthStore } from '@/stores/auth';

export default {
  name: 'App',
  components: {
    BlockUI,
    AppBar,
    NavigationDrawer,
    Profile,
    MyEssentials,
    AppIcon,
    Notifications,
    ConfirmationDialog
  },
  mounted() {
    setupHttp(this.httpErrorHandler);

    this.$eventHub.$on('open:confirmation', this.openConfirmationDialog);
    this.$eventHub.$on('toggle:notifications', this.markNewNotificationsAsRead);
    this.$eventHub.$on('delete:notification', this.deleteNotification);

    Vue.prototype.$OAuth.afterLogin = () => {
      Vue.prototype.$OAuth.afterLogin = undefined;

      if (this.errorDialog && this.errorDialog.errors.length == 1 && this.errorDialog.errors[0].split('\nURL:')[0] == 'Unauthorized') {
        // Remove "Unauthorized" error dialog
        this.errorDialog = undefined;
      }

      let ready = Promise.resolve();
      if (this.loginFailed) {
        ready = this.fetchUser();
      }

      const currentPath = this.$router.currentRoute.path;
      const path = localStorage.getItem('eppURL');
      if (currentPath !== path) {
        ready.then(() => {
          this.$router.push({ path });
        });
      }
    };

    this.fetchUser();
  },
  beforeDestroy() {
    this.$eventHub.$off('open:confirmation');
    this.$eventHub.$off('toggle:notifications');
    this.$eventHub.$off('delete:notification');
  },
  data() {
    return {
      isAuthenticated: false,
      notificationsData: [],
      notificationsTimeout: undefined,
      confirmationDialog: undefined,
      errorDialog: undefined,
      essentialsData: undefined,
      loginFailed: false,
      essentialUrl: process.env.VUE_APP_ESSENTIALS_URL
    };
  },
  computed: {
    loggedInUserRole() {
      const auth = useAuthStore();
      return auth.role;
    },
    showNavSidebar() {
      const auth = useAuthStore();
      return auth.status === 'Active';
    },
    navDrawerItems() {
      const items = [
        {
          title: 'Browse Events',
          icon: 'event',
          iconOutlined: true,
          route: '/',
          exact: true
        }
      ];
      if (this.loggedInUserRole == 'Super Admin') {
        items.push({
          title: 'Admin Dashboard',
          icon: 'manage_accounts',
          iconOutlined: false,
          route: '/dashboard'
        });
      }
      if (this.loggedInUserRole == 'Super Admin') {
        items.push({
          title: 'Reporting Dashboard',
          icon: 'insert_chart_outlined',
          iconOutlined: true,
          route: '/reporting'
        });
      }
      items.push({
        title: 'Frequently Asked Questions',
        icon: 'mdi-help-circle-outline',
        iconOutlined: false,
        route: '/faq'
      })
      return items;
    },
    newNotifications() {
      return this.notificationsData.some(notification => !notification.was_read);
    },
    profile() {
      const auth = useAuthStore()
      if (!this.loggedInUserRole) {
        return {}
      }

      return auth
    }
  },
  methods: {
    openConfirmationDialog(data) {
      this.confirmationDialog = data;
    },
    markNewNotificationsAsRead() {
      if (!this.newNotifications) {
        return;
      }
      this.$api.notifications.markAsRead().then(() => {
        const notifications = [...this.notificationsData];
        notifications.forEach(notification => {
          notification.was_read = true;
        });
        this.notificationsData = notifications;
      });
    },
    deleteNotification(id) {
      this.$api.notifications.remove(id).then(() => {
        this.notificationsData = this.notificationsData.filter(notification => notification.id != id);
      });
    },
    fetchNotifications() {
      const lastNotification = this.notificationsData[this.notificationsData.length - 1]
      this.$api.notifications.fetch(lastNotification ? lastNotification.id : undefined).then(response => {
        if (response && response.notifications) {
          const existingIds = this.notificationsData.map(notification => notification.id)
          const notifications = response.notifications.filter(notification => !existingIds.includes(notification.id))
          this.notificationsData = this.notificationsData.concat(notifications);

          if (this.$refs.notifications && this.$refs.notifications.drawer) {
            this.markNewNotificationsAsRead();
          }
        }

        this.notificationsTimeout = setTimeout(this.fetchNotifications, 60 * 1000);
      });
    },
    fetchUser() {
      return this.$api.user.fetch().then(response => {
        if (response && response.user) {
          const auth = useAuthStore();
          auth.$patch({
            ...response.user
          });

          // Check if the user's role is either 'Super Admin' or 'Event Admin' and the status is not 'Inactive'
          if ((auth.role !== 'Super Admin' && auth.role !== 'Event Admin') || auth.status === 'Inactive') {
            this.$router.push('/no-access');
          }
          // Optionally fetch notifications or other data here if the role and status check passes
        } else {
          // Redirect if user data is not found
          this.$router.push('/no-access');
        }
      }).catch(() => {
        // Redirect to no-access if any error occurs during fetch
        this.$router.push('/no-access');
        this.loginFailed = true;
      });
    },
    httpErrorHandler(error) {
      if (error.response && error.response.status === 300) {
        return;
      } else if (error.response && error.response.status === 401) {
        // We had authentication to make the request, but it was rejected.

        // Prevent potential infinite loop by not re-refreshing authorisation
        // immediately after returning from being authorised
        if (window.location.pathname != '/login/callback') {
          // TODO - Implement refresh
          localStorage.setItem('eppURL', window.location.pathname);
          Vue.prototype.$OAuth.getAuth();
          return;
        }
        this.loginFailed = true;
      }
      if (error.config && error.config.url == 'notificationsRetrieve') {
        return;
      }

      let errors = [];
      const data = error.response && error.response.data;
      if (data) {
        if (data.errors) {
          data.errors.forEach(error => {
            if (typeof error === 'string') {
              errors.push(error);
            } else if (error.msg) {
              errors.push(error.msg + ': ' + error.param);
            }
          });
        } else if (typeof data === 'string') {
          errors.push(data);
        }
      } else if (error.message) {
        errors.push(error.message)
      }
      errors = errors.map(errorText => {
        return errorText + '\nURL: ' + error.config.url;
      });
      this.errorDialog = {
        errors: errors.length ? [...new Set(errors)] : ['An error occurred']
      };
    },
    updateProfileHandler() {
      window.location = 'https://portal.education.nsw.gov.au/profileCentre/profile/';
    },
    changePasswordHandler() {
      window.location = 'https://portal.education.nsw.gov.au/profileCentre/profile/';
    },
    updateSecretQuestionHandler() {
      window.location = 'https://portal.education.nsw.gov.au/profileCentre/profile/';
    },
    logoutHandler() {
      window.location = 'https://ssoaddonsext.det.nsw.edu.au/ssoaddon/logout';
    }
  }
};
</script>

<style lang="scss">
// use pre-defined ADS scss classes for the whole project, note here the styles are non-scoped in App.vue
@import "./scss/ads";

.v-app-bar .title--width {
  width: calc(100% - 298px);
}

.banner {
  margin-bottom: 0;
}

.container {
  padding: 42px 30px;
}

.v-dialog .v-avatar {
  background-color: $ads-light-blue;

  @media (prefers-color-scheme: dark) {
    color: $ads-dark;

    .v-icon {
      color: inherit;
    }
  }
}

.v-dialog .theme--light.v-card>.v-card__text {
  color: $ads-grey-01;
}

.v-form .v-input.error--text {
  fieldset {
    border-width: 3px;
  }

  .v-messages {
    font-size: 14px;
  }

  &::after {
    content: "error_outline";
    font-family: "Material Icons";
    font-size: 24px;
    position: absolute;
    top: 18px;
    right: 12px;
    background-color: $ads-white;
  }

  &.v-input--radio-group {
    position: relative;

    &::after {
      top: 31px;
    }
  }
}

.v-chip--select {
  border: 2px solid;
  border-color: $ads-white !important;

  &:not(.v-chip--active) {
    color: $ads-navy;
    background: $ads-light-blue !important;
    border-color: $ads-navy !important;

    @media (prefers-color-scheme: dark) {
      background: none !important;
      color: #2196f3;
      border-color: #2196f3 !important;
    }

    button {
      color: inherit;
    }
  }
}

.search-above-table {
  background-color: $ads-white;

  @media (prefers-color-scheme: dark) {
    background-color: $ads-12dp;
  }

  .v-input__slot::before {
    display: none;
  }

  .v-icon {
    color: $ads-dark-60;
  }
}

.search-above-table,
.search-above-table+.v-data-table {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 4px 4px 0 0;

  @media (prefers-color-scheme: dark) {
    border-bottom-color: rgba(255, 255, 255, 0.12);
  }
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 44px;
  padding: 0 16px;
}

.v-btn--outlined:not(.border) {
  border: none;
}

.v-input--radio-group strong {
  width: 100%;
}

@media (min-width: 601px) {
  .v-dialog .halves {
    display: flex;
    justify-content: space-between;

    .half {
      width: calc((100% - 17px) / 2);
      flex-grow: 0;
    }
  }
}

/* Originally copied from @nswdoe/doe-ui-core Dialog for when the actions slot is used */
.v-dialog .v-card__actions {
  display: flex;
  justify-content: flex-end;
  padding: 0 24px 24px 24px !important;

  .v-btn {
    margin-left: 12px !important;
    padding: 0 16px;
    color: $ads-white;

    @media (prefers-color-scheme: dark) {
      &.v-btn--has-bg {
        color: $ads-dark !important;
      }
    }
  }
}

.v-autocomplete__content.hidden {
  display: none;
}

/* Align lower left alert to the edge of the drawer */
.v-navigation-drawer:first-child {
  &:not(.v-navigation-drawer--mini-variant)~main .alert--snackbar .v-snack--left {
    left: 308px !important;
  }

  &.v-navigation-drawer--is-mobile {
    ~main .alert--snackbar .v-snack--left {
      left: 16px !important;
    }

    &.v-navigation-drawer--open~main .alert--snackbar .v-snack--left {
      left: 308px !important;
    }
  }
}

@media print {

  main,
  .container {
    padding: 0 !important;
  }
}
</style>
