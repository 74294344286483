import 'babel-polyfill';

import Vue from 'vue';
import { createPinia, PiniaVuePlugin } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

import { IeBlocker, OAuth } from '@nswdoe/doe-ui-core';

import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import api from './api';
import Header from '@/components/Header';

// Enhance the event system on Vue.prototype
import { useEventHub, useUpwardBroadcast, useDownwardBroadcast } from './hooks/events';
useEventHub(Vue);
useUpwardBroadcast(Vue);
useDownwardBroadcast(Vue);

// Fixes "ReferenceError: Buffer is not defined" from base64url after @nswdoe/doe-ui-core authCrypto.js
window.Buffer = window.Buffer || require('buffer').Buffer;

Vue.use(OAuth, {
  router: router,
  pkce: true,
  authoriseUri: process.env.VUE_APP_AUTH_URL,
  logoutUri: process.env.VUE_APP_AUTH_LOGOUT_URL,
  tokenUri: process.env.VUE_APP_AUTH_TOKEN_URL,
  secureApp: false,
  forceProdMode: true,
  params: {
    client_id: process.env.VUE_APP_AUTH_CLIENT_ID,
    redirect_uri: encodeURIComponent(`${window.location.origin}/login/callback`),
    scope: encodeURIComponent('openid allatclaims')
  }
});

Vue.use(PiniaVuePlugin);
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate)


/**
 * The mixin for implementing dynamic <title> in SPA according to different 'view/page' the user views.
 * It's to support better accessibility.
 * The mixin is from the official doc https://ssr.vuejs.org/guide/head.html
 */
import titleMixin from './mixins/titleMixin';
Vue.mixin(titleMixin);

let renderComponent, renderProps;

if (IeBlocker.isIe()) { // render IeBlocker
  renderComponent = IeBlocker;
  renderProps = {
    props: {
      block: true,
      appName: 'EPP Initiative Management Application'
    }
  };
} else { // render App, config the App root instance (oAuth plugin, etc.
  renderComponent = App
  renderProps = {};

  Vue.config.productionTip = false;

  /**
   * Unified interface for API requests
   * Added onto Vue.prototype object, so all Vue component instances can access it via `this.$api`
   */
  Vue.prototype.$api = api;

  /**
   * Register global components, or components that are used across views/pages,
   * so no need to register them in each view/page.
   * These components are placed inside `src/components`
   */
  Vue.component('EppHeader', Header);
}

new Vue({
  router,
  vuetify,
  pinia,
  render: h => h(renderComponent, renderProps)
}).$mount('#app');
