<template>
  <div>
    <div
      class="pl-6 pr-6 pt-6"
      style="background-color: white"
    >
      <v-row>
        <v-col cols="7">
          <div
            class="subtitle-2"
            style="color: #407ec9"
          >
            {{ event.date_string }}
          </div>
          <h2
            class="title mb-2 mt-2"
            style="color: #22272b"
          >
            {{ event.title }}
          </h2>
          <div
            class="body-2"
            v-if="event.format == 'Online'"
          >
            <a :href="event.access_link">{{ event.access_link }}</a>
          </div>
          <div
            class="body-2"
            v-else
          >
            {{ event.venue_address }}
          </div>
        </v-col>
        <v-col cols="5">
          <div class="d-flex justify-end">
            <div class="d-flex flex-column align-end">
              <Chip
                :text="event.status.toUpperCase()"
                :color="getBackgroundColorForStatus(event.status)"
                :textColor="getTextColorForStatus(event.status)"
                small
                class="font-weight-bold"
              />

              <div
                class="mt-1"
                v-if="event.status !== 'cancelled'"
              >
                <strong>{{ event.available_spots }}</strong>/{{ event.capacity }} spots available
              </div>

              <div
                class="mt-1"
                v-if="event.status === 'cancelled'"
              >
                <strong>Reason: </strong>{{ event.cancellation_reason }}
              </div>
            </div>

            <v-menu
              offset-y
              class="custom-menu"
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  style="margin-right: -13px; margin-top: -13px"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <router-link
                  :to="`/event/${event.id}`"
                  style="text-decoration: none"
                  v-if="event.status !== 'cancelled'"
                >
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon>mdi-calendar</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Go to event</v-list-item-title>
                  </v-list-item>
                </router-link>

                <v-list-item
                  v-if="
                    event.requires_registration && event.status !== 'cancelled'
                  "
                  @click="openStudentRegistrationModal(event)"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-account-plus</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Add registrations</v-list-item-title>
                </v-list-item>

                <v-list-item
                  v-if="event.status !== 'cancelled'"
                  @click="toggleEditDialog(event)"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-pencil</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Edit event</v-list-item-title>
                </v-list-item>

                <v-list-item @click="toggleEditDialog(event, true)">
                  <v-list-item-icon>
                    <v-icon>mdi-content-copy</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Duplicate event</v-list-item-title>
                </v-list-item>

                <v-list-item
                  @click="showCancelDialog = true"
                  v-if="event.status !== 'cancelled'"
                >
                  <v-list-item-icon>
                    <v-icon class="cancel-icon">
                      mdi-close-circle-outline
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="cancel-text">
                    Cancel event
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-col>
      </v-row>
    </div>
    <v-expansion-panels
      accordion
      class="event-panel"
    >
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div>
            <Chip
              :text="event.initiative_type"
              outlined
              color="#407EC9"
              textColor="#407EC9"
              class="mr-2"
              small
            />
            <Chip
              :text="event.category"
              outlined
              color="#407EC9"
              textColor="#407EC9"
              class="mr-2"
              small
            />

            <Chip
              v-if="event.combined_event"
              text="VA combined event"
              outlined
              color="#407EC9"
              textColor="#407EC9"
              small
            />
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col cols="5">
              <h4 class="subtitle-2 mb-2 mt-3">
                Description
              </h4>
              <p class="body-2">
                {{ event.description }}
              </p>
            </v-col>

            <v-col cols="2" />

            <v-col cols="5">
              <h4 class="subtitle-2 mb-2 mt-3">
                Available to:
              </h4>
              <p class="body-2">
                {{ findSchoolsByIds(schools, event.schools).join(", ") }}
              </p>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <CancelDialog
      :openDialog="showCancelDialog"
      title="Cancel event"
      maxWidth="520px"
      icon="security"
      :displayCloseBtn="true"
      :actions="actions"
      @close="showCancelDialog = false"
      :return-handler="handleCancelDialogReturn"
    >
      <template #text>
        <v-card-text>
          <p class="font-weight-bold">
            Please select a reason for cancelling this event.
          </p>
          <AdsSelect
            v-model="reason"
            label="Please select"
            :items="reasons"
            placeholder="Please select"
            item-text="name"
            item-value="id"
            :rules="[validation.required]"
          />
        </v-card-text>
      </template>
    </CancelDialog>
  </div>
</template>

<script>
import {
  ADS_Colors,
  Chip,
  Dialog as CancelDialog,
  AdsSelect,
} from '@nswdoe/doe-ui-core';

export default {
  name: 'EventCard',
  components: {
    Chip,
    CancelDialog,
    AdsSelect,
  },
  props: {
    event: {
      type: Object,
      default: () => ({}),
    },
    schools: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      ADS_Colors,
      actions: [
        {
          name: 'Close',
          color: '#041E42',
          size: 'large',
          outlined: true,
          close: true,
        },
        {
          name: 'Confirm cancellation',
          color: '#041E42',
          size: 'large',
          outlined: false,
        },
      ],
      showCancelDialog: false,
      reason: undefined,
      reasons: [
        'School availability/scheduling issues',
        'School routine change',
        'Staff/presenter availability/scheduling issues',
        'Weather/natural disaster',
      ],
      validation: {
        required: (value) => !!value || 'This field is required',
      },
    };
  },
  methods: {
    async handleCancelDialogReturn(result) {
      if (result === 'Confirm cancellation') {
        try {
          await this.$api.events.cancel({
            id: this.event.id,
            status: 'cancelled',
            cancellation_reason: this.reason,
          });

          // Emit the eventCancelled event only after the cancel operation is complete
          this.$emit('eventCancelled');
        } catch (error) {
          // Handle any errors that occur during the cancellation
          // console.error('Error cancelling event:', error);
          // Optionally emit a different event or update the state to reflect the error
        }
      }
      this.showCancelDialog = false;
    },
    toggleEditDialog(event, duplicate = false) {
      this.$emit('editDialogToggled', event, duplicate);
    },
    openStudentRegistrationModal(event) {
      this.$emit('openStudentRegistration', event);
    },
    duplicateEvent(id) {
      this.$api.events.duplicate(id);
    },
    getBackgroundColorForStatus(status) {
      switch (status) {
        case 'available':
          return '#407EC9'; // light blue
        case 'cancelled':
          return '#D7153A'; // red
        case 'completed':
          return '#00AA45'; // green
        case 'full':
          return '#002664'; // dark blue
        case 'incomplete':
          return '#F3631B'; // orange
        case 'closed':
          return '#EBEBEB'; // light grey
        case 'not required':
          return '#495054'; // dark grey
        default:
          return 'grey'; // Default color for unknown statuses
      }
    },
    getTextColorForStatus(status) {
      switch (status) {
        case 'closed':
          return '#495054'; // light grey
        default:
          return 'white'; // Default color for unknown statuses
      }
    },
    findSchoolsByIds(schoolsList, ids) {
      const result = [];
      function searchGroups(groups) {
        for (const group of groups) {
          if (group.schools) {
            for (const school of group.schools) {
              if (ids.includes(school.id)) {
                result.push(school.name);
              }
            }
          }
          if (group.groups) {
            searchGroups(group.groups);
          }
        }
      }
      searchGroups(schoolsList);
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-expansion-panels {
  border-radius: 0 0 4px 4px;
}

.cancel-icon {
  color: red;
}

.cancel-text {
  color: red;
}

.custom-menu .v-list-item:hover {
  cursor: pointer;
}
</style>
