<template>
  <v-container>
    <v-form
      ref="editEventForm"
      :key="formKey"
    >
      <v-stepper
        v-model="editEventStep"
        vertical
      >
        <v-stepper-step
          :complete="editEventStep > 1"
          step="1"
        >
          Event Details
        </v-stepper-step>

        <v-stepper-content step="1">
          <v-row>
            <v-col cols="12" />
          </v-row>
          <v-row>
            <v-col cols="12">
              <AdsTextField
                v-model="editEvent.title"
                label="Event title *"
                placeholder="Enter event title"
                :rules="[validation.required]"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <AdsSelect
                v-model="editEvent.initiative_type"
                label="Initiative type *"
                :items="filteredInitiativeTypes"
                placeholder="Please select"
                :rules="[validation.required]"
              />
            </v-col>
          </v-row>

          <v-switch
            v-if="
              editEvent.initiative_type == 'Innovating Careers Education' ||
                editEvent.initiative_type == 'Job Readiness'
            "
            style="margin-top: 0"
            v-model="editEvent.combined_event"
            label="VA combined event"
          />

          <v-row>
            <v-col cols="12">
              <AdsSelect
                v-model="editEvent.category"
                label="Category *"
                :items="filteredCategories"
                placeholder="Please select"
                :rules="[validation.required]"
                :hint="registrationRequiredMessage"
                persistent-hint
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <AdsSelect
                v-model="editEvent.activity_type"
                label="Activity type *"
                :items="filteredActivities"
                :rules="[validation.required]"
                placeholder="Please select"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <AdsTextArea
                v-model="editEvent.description"
                label="Description *"
                :rules="[validation.required]"
                placeholder="Enter event description"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <AdsSelect
                v-model="editEvent.presenter_type"
                label="Presenter type"
                hint="If unknown, this can be completed later"
                persistent-hint
                :items="presenterTypes"
                placeholder="Please select"
              />
            </v-col>
          </v-row>

          <v-row v-if="editEvent.presenter_type == 'Employer'">
            <v-col cols="12">
              <AdsTextField
                v-model="editEvent.presenter_number"
                label="Number of employers"
                hint="If unknown, this can be completed later"
                persistent-hint
                :rules="[validation.optionalNumber]"
              />
            </v-col>
          </v-row>
          <v-row v-if="editEvent.presenter_type == 'Employer'">
            <v-col cols="12">
              <AdsTextField
                v-model="editEvent.presenter_name"
                label="Employer name"
                hint="If unknown, this can be completed later"
                persistent-hint
              />
            </v-col>
          </v-row>
          <v-row v-if="editEvent.presenter_type == 'GTO'">
            <v-col cols="12">
              <AdsTextField
                v-model="editEvent.presenter_name"
                label="Name of GTO"
                hint="If unknown, this can be completed later"
                persistent-hint
              />
            </v-col>
          </v-row>
          <v-row v-if="editEvent.presenter_type == 'HTC'">
            <v-col cols="12">
              <AdsTextField
                v-model="editEvent.presenter_number"
                label="Number of HTCs"
                hint="If unknown, this can be completed later"
                persistent-hint
                :rules="[validation.optionalNumber]"
              />
            </v-col>
          </v-row>
          <v-row v-if="editEvent.presenter_type == 'HTC'">
            <v-col cols="12">
              <AdsTextField
                v-model="editEvent.presenter_name"
                label="Name of HTC"
                hint="If unknown, this can be completed later"
                persistent-hint
              />
            </v-col>
          </v-row>
          <v-row v-if="editEvent.presenter_type == 'Job Readiness Provider'">
            <v-col cols="12">
              <AdsTextField
                v-model="editEvent.presenter_number"
                label="Number of presenters from job readiness"
                hint="If unknown, this can be completed later"
                persistent-hint
                :rules="[validation.optionalNumber]"
              />
            </v-col>
          </v-row>
          <v-row v-if="editEvent.presenter_type == 'RIEP'">
            <v-col cols="12">
              <AdsTextField
                v-model="editEvent.presenter_number"
                label="Number of presenters from RIEP"
                :rules="[validation.optionalNumber]"
                hint="If unknown, this can be completed later"
                persistent-hint
              />
            </v-col>
          </v-row>
          <v-row v-if="editEvent.presenter_type == 'RTO'">
            <v-col cols="12">
              <AdsTextField
                v-model="editEvent.presenter_name"
                label="Name of RTO"
                hint="If unknown, this can be completed later"
                persistent-hint
              />
            </v-col>
          </v-row>
          <v-row v-if="editEvent.presenter_type == 'SBATEO'">
            <v-col cols="12">
              <AdsTextField
                v-model="editEvent.presenter_number"
                label="Number of SBATEOs"
                :rules="[validation.optionalNumber]"
                hint="If unknown, this can be completed later"
                persistent-hint
              />
            </v-col>
          </v-row>
          <v-row v-if="editEvent.presenter_type == 'SBATEO'">
            <v-col cols="12">
              <AdsTextField
                v-model="editEvent.presenter_name"
                label="SBATEO names"
                hint="If unknown, this can be completed later"
                persistent-hint
              />
            </v-col>
          </v-row>
          <v-row v-if="editEvent.presenter_type == 'VET Ambassadors'">
            <v-col cols="12">
              <AdsTextField
                v-model="editEvent.presenter_number"
                label="Number of VET Ambassadors"
                :rules="[validation.optionalNumber]"
                hint="If unknown, this can be completed later"
                persistent-hint
              />
            </v-col>
          </v-row>
          <v-row v-if="editEvent.presenter_type == 'VET Ambassadors'">
            <v-col cols="12">
              <AdsTextField
                v-model="editEvent.presenter_name"
                label="VET Ambassador names"
                hint="If unknown, this can be completed later"
                persistent-hint
              />
            </v-col>
          </v-row>
          <v-row v-if="editEvent.presenter_type == 'Other'">
            <v-col cols="12">
              <AdsTextField
                v-model="editEvent.presenter_other"
                label="Please specify"
                hint="If unknown, this can be completed later"
                persistent-hint
              />
            </v-col>
          </v-row>

          <Alert
            v-if="showStepError && !categoryError"
            showAlert
            type="error"
            text="Please complete all required fields indicated by a *"
            inPage
            :backgroundColour="true"
          />
          <v-row>
            <v-col cols="12">
              <v-btn
                color="primary"
                @click="moveToStep(2)"
              >
                Continue
              </v-btn>
              <v-btn
                text
                @click="toggleDialog(false)"
              >
                Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>

        <v-stepper-step
          :complete="editEventStep > 2"
          step="2"
        >
          Scheduling
        </v-stepper-step>

        <v-stepper-content step="2">
          <v-row>
            <v-col cols="12">
              <p><strong>Date and time</strong></p>
            </v-col>
            <v-col cols="6">
              <DatePicker
                v-model="editEvent.start_date"
                label="Start date *"
                id="start_date"
                :rules="[validation.required]"
              />
            </v-col>
            <v-col cols="6">
              <DatePicker
                v-model="editEvent.end_date"
                label="End date *"
                id="end_date"
                :rules="[validation.required]"
                :datePickerAttrs="{
                  min: editEvent.start_date,
                }"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <v-dialog
                ref="startTimeDialog"
                v-model="startTimeDialog"
                width="290px"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="editEvent.start_time"
                    label="Start time *"
                    prepend-inner-icon="mdi-clock-time-four-outline"
                    outlined
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-time-picker
                  v-model="editEvent.start_time"
                  full-width
                  ampm-in-title
                  format="ampm"
                  :rules="[validation.required]"
                />
              </v-dialog>
            </v-col>
            <v-col cols="6">
              <v-dialog
                ref="endTimeDialog"
                v-model="endTimeDialog"
                width="290px"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="editEvent.end_time"
                    label="End time *"
                    prepend-inner-icon="mdi-clock-time-four-outline"
                    outlined
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-time-picker
                  v-model="editEvent.end_time"
                  full-width
                  ampm-in-title
                  format="ampm"
                  :rules="[validation.required]"
                />
              </v-dialog>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <AdsSelect
                v-model="editEvent.term"
                label="Term *"
                :items="terms"
                placeholder="Please select"
                :rules="[validation.required]"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <p><strong>Location</strong></p>
            </v-col>

            <v-col cols="12">
              <div style="font-weight: bold">
                Delivery *
              </div>
              <v-radio-group
                v-model="editEvent.format"
                row
                @change="setupGoogleMapsAutocomplete"
              >
                <v-radio
                  v-for="(format, index) in formats"
                  :label="format"
                  :value="format"
                  :key="index"
                />
              </v-radio-group>
            </v-col>

            <v-col>
              <AdsTextField
                v-model="editEvent.venue_address"
                label="Enter a location *"
                :rules="[validation.required]"
                placeholder="Type address"
                v-if="editEvent.format == 'In-person'"
                ref="addressInput"
              />

              <AdsTextField
                v-model="editEvent.access_link"
                label="Access link *"
                :rules="[validation.required, validation.url]"
                placeholder="Type URL"
                v-if="editEvent.format == 'Online'"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <AdsTextField
                v-model="editEvent.capacity"
                label="Capacity *"
                placeholder="Please enter a number"
                :rules="[validation.number]"
              />
            </v-col>
          </v-row>

          <Alert
            v-if="showStepError && !capacityError"
            showAlert
            type="error"
            text="Please complete all required fields indicated by a *"
            inPage
            :backgroundColour="true"
          />
          <v-row>
            <v-col cols="12">
              <v-btn
                color="primary"
                @click="moveToStep(3)"
              >
                Continue
              </v-btn>
              <v-btn
                text
                @click="editEventStep = 1"
              >
                Previous
              </v-btn>
              <v-btn
                text
                @click="toggleDialog(false)"
              >
                Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>

        <v-stepper-step
          :complete="editEventStep > 3"
          step="3"
        >
          Participation
        </v-stepper-step>

        <v-stepper-content step="3">
          <v-row>
            <v-col cols="12">
              <p><strong>School type *</strong></p>

              <v-radio-group
                v-model="editEvent.school_type"
                row
              >
                <v-radio
                  label="EPP school"
                  value="EPP school"
                />
                <v-radio
                  label="Flexible delivery school"
                  value="Flexible delivery school"
                />
              </v-radio-group>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <AdsSelect
                v-model="editEvent.selected_region_ids"
                label="Region *"
                :items="filteredRegions"
                placeholder="Please select"
                item-text="name"
                item-value="id"
                :rules="[validation.required]"
                multiple
                chips
              />
            </v-col>

            <v-col cols="6">
              <AdsSelect
                v-model="editEvent.selected_group_ids"
                label="School group *"
                :items="filteredSchoolGroups"
                placeholder="Please select"
                item-text="name"
                item-value="id"
                :rules="[validation.required]"
                multiple
                chips
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <AdsSelect
                v-model="editEvent.schools"
                label="Schools *"
                :items="filteredSchools"
                :rules="[validation.required]"
                placeholder="Please select"
                item-text="name"
                item-value="id"
                multiple
                chips
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <AdsSelect
                v-model="editEvent.audiences"
                label="Audiences *"
                :items="filteredAudiences"
                multiple
                chips
                :rules="[validation.required]"
                placeholder="Please select"
              />
            </v-col>
          </v-row>

          <Alert
            v-if="showStepError && !audienceError && !schoolsError"
            showAlert
            type="error"
            text="Please complete all required fields indicated by a *"
            inPage
            :backgroundColour="true"
          />

          <v-row>
            <v-col cols="12">
              <v-btn
                color="primary"
                @click="saveEventChanges"
              >
                <div v-if="!dulplicateEvent">
                  Edit event
                </div>
                <div v-if="dulplicateEvent">
                  Duplicate event
                </div>
              </v-btn>
              <v-btn
                text
                @click="editEventStep = 2"
              >
                Previous
              </v-btn>
              <v-btn
                text
                @click="toggleDialog(false)"
              >
                Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>
      </v-stepper>
    </v-form>

    <AdsDialog
      :openDialog="openErrorDialog"
      :title="errorDialogTitle"
      maxWidth="700px"
      icon="security"
      :displayCloseBtn="true"
      :message="errorDialogMessage"
      :actions="errorDialogActions"
      @close="openErrorDialog = false"
      class="custom-dialog"
      :return-handler="handleDialogReturn"
    />
  </v-container>
</template>

<script>
/* global google */

import {
  ADS_Colors,
  AdsTextField,
  AdsSelect,
  AdsTextArea,
  DatePicker,
  Alert,
  Dialog as AdsDialog,
} from '@nswdoe/doe-ui-core';

import { useAuthStore } from '@/stores/auth';
import { useInitiativeTypesStore } from '@/stores/initiativeTypes';
import { useCategoriesStore } from '@/stores/categories';
import { useActivityTypesStore } from '@/stores/activityTypes';
import { useTermsStore } from '@/stores/terms';
import { useFormatsStore } from '@/stores/formats';
import { useAudiencesStore } from '@/stores/audiences';
import { usePresenterTypesStore } from '@/stores/presenterTypes';

import { zonedTimeToUtc } from 'date-fns-tz';

export default {
  name: 'EditEvent',
  components: {
    AdsTextField,
    AdsSelect,
    AdsTextArea,
    DatePicker,
    Alert,
    AdsDialog
  },
  props: {
    event: {
      type: Object,
      default: () => ({}),
    },
    dulplicateEvent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      openErrorDialog: false,
      errorDialogTitle: 'Validation Error',
      errorDialogMessage: '',
      errorDialogActions: [
        { name: 'Close', color: '#041E42', size: 'large', outlined: false }
      ],
      // Error messages
      capacityError: '',
      categoryError: '',
      audienceError: '',
      schoolsError: '',
      formKey: 0,
      user: {},
      editEvent: {
        title: null,
        initiative_type: null,
        category: null,
        activity_type: null,
        description: null,
        combined_event: null,
        presenter_type: null,
        presenter_number: null,
        presenter_name: null,
        presenter_other: null,
        start_datetime: null,
        end_datetime: null,
        term: null,
        venue_address: null,
        access_link: null,
        format: null,
        capacity: null,
        school_type: null,
        schools: [],
        audiences: [],
        start_date: null,
        end_date: null,
        start_time: null,
        end_time: null,
        selected_region_ids: [],
        selected_group_ids: [],
      },
      showStepError: false,
      startTimeDialog: false,
      endTimeDialog: false,
      editEventStep: 1,
      ADS_Colors,
      isMounted: false,
      validation: {
        required: (value) => !!value || 'This field is required',
        number: (value) => {
          if (!value) return 'This field is required';
          const number = parseFloat(value);
          return (!isNaN(number) && /^\d+(\.\d+)?$/.test(value) && number > 0) || 'Please enter a number greater than 0';
        },
        optionalNumber: (value) => {
          if (!value) return true; // Allow empty value
          const number = parseFloat(value);
          return (!isNaN(number) && /^\d+(\.\d+)?$/.test(value) && number > 0) || 'Please enter a number greater than 0';
        },
        url: (value) =>
          !value ||
          new RegExp(
            '^(https?:\\/\\/)([\\da-z.-]+)\\.([a-z.]{2,6})([\\/\\w .-]*)*\\/?$'
          ).test(value) ||
          'Please enter a valid url, including https:// or http://'
      },
      schools: [],
      transformedSchoolData: [],
      regions: [],
      schoolGroups: [],
      actions: [
        { name: 'Close', color: '#041E42', size: 'large', outlined: false }
      ],
    };
  },
  watch: {
    'editEvent.selected_region_ids'(newVal, oldVal) {
      if (newVal.length < oldVal.length) {
        const removedRegions = oldVal.filter((region) => !newVal.includes(region));
        this.editEvent.selected_group_ids = this.editEvent.selected_group_ids.filter(
          (groupId) =>
            !removedRegions.some((regionId) =>
              this.schoolGroups.some(
                (group) => group.id === groupId && group.region_id === regionId
              )
            )
        );
        this.editEvent.schools = this.editEvent.schools.filter(
          (schoolId) =>
            !removedRegions.some((regionId) =>
              this.schools.some((region) =>
                region.groups.some(
                  (group) =>
                    group.schools.some((school) => school.id === schoolId) &&
                    group.region_id === regionId
                )
              )
            )
        );
      }
    },
    'editEvent.selected_group_ids'(newVal, oldVal) {
      if (newVal.length < oldVal.length) {
        const removedGroups = oldVal.filter((group) => !newVal.includes(group));
        this.editEvent.schools = this.editEvent.schools.filter(
          (schoolId) =>
            !removedGroups.some((groupId) =>
              this.schools.some((region) =>
                region.groups.some(
                  (group) =>
                    group.schools.some((school) => school.id === schoolId) &&
                    group.id === groupId
                )
              )
            )
        );
      }
    },
    'editEvent.presenter_type'(newVal, oldVal) {
      if (this.isMounted && newVal !== oldVal) {
        this.$set(this.editEvent, 'presenter_number', null);
        this.$set(this.editEvent, 'presenter_name', null);
        this.$set(this.editEvent, 'presenter_other', null);
      }
    },
    event: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.editEvent = { ...this.event };
          this.editEvent.presenter_number = this.editEvent.presenter_number || null;
          this.editEvent.presenter_name = this.editEvent.presenter_name || null;
          this.editEvent.presenter_other = this.editEvent.presenter_other || null;
          this.editEventStep = 1; // Reset step to 1 when event changes
        } else {
          this.resetForm();
        }
      },
    },
    'editEvent.initiative_type'(newVal, oldVal) {
      if (this.isMounted && newVal !== oldVal) {
        this.editEvent.category = null;
        this.editEvent.activity_type = null;
        // Reset combined_event flag if initiative_type is not 'Job Readiness' or 'Innovating Careers Education'
        if (newVal !== 'Job Readiness' && newVal !== 'Innovating Careers Education') {
          this.editEvent.combined_event = false;
        }
      }
    },
    'editEvent.school_type'(newVal, oldVal) {
      if (this.isMounted && newVal !== oldVal) {
        this.editEvent.selected_region_ids = [];
        this.editEvent.selected_group_ids = [];
      }
    },
    'editEvent.start_date'(newStartDate) {
      if (
        newStartDate &&
        (!this.editEvent.end_date ||
          new Date(this.editEvent.end_date) < new Date(newStartDate))
      ) {
        this.editEvent.end_date = newStartDate;
      }
    },
    'editEvent.format'(newVal, oldVal) {
      if (newVal !== oldVal) {
        if (newVal === 'Online') {
          this.editEvent.venue_address = '';
        } else if (newVal === 'In-person') {
          this.editEvent.access_link = '';
          this.$nextTick(() => {
            this.setupGoogleMapsAutocomplete();
          });
        }
      }
    },
  },
  computed: {
    filteredRegions() {
      // Check if user is "Super Admin" and return all regions if true
      if (this.user && this.user.role === 'Super Admin') {
        return this.regions;
      }

      // Proceed with filtering for "Event Admin"
      if (!this.user || !Array.isArray(this.user.regions)) return [];
      return this.regions.filter(region => this.user.regions.includes(region.id));
    },
    filteredSchoolGroups() {
      // Check if user is "Super Admin" to determine if filtering by user's groups is necessary
      const isSuperAdmin = this.user && this.user.role === 'Super Admin';

      // Always proceed with filtering based on selected region IDs
      if (!this.editEvent.selected_region_ids || this.editEvent.selected_region_ids.length === 0) {
        return [];
      }

      // Filter school groups by region. If user is not "Super Admin", additionally filter by user's allowed groups.
      const userAllowedSchoolGroups = this.user && Array.isArray(this.user.groups) ? this.user.groups : [];
      return this.schoolGroups.filter(
        (group) =>
          this.editEvent.selected_region_ids.includes(group.region_id) &&
          (isSuperAdmin || userAllowedSchoolGroups.includes(group.id))
      );
    },
    filteredSchools() {
      let schools = [];
      for (let region of this.schools) {
        for (let group of region.groups) {
          for (let school of group.schools) {
            if (
              (!this.editEvent.selected_group_ids ||
                this.editEvent.selected_group_ids.includes(school.school_group_id)) &&
              (!this.editEvent.school_type || this.editEvent.school_type === school.school_type)
            ) {
              schools.push(school);
            }
          }
        }
      }
      return schools;
    },
    registrationRequiredMessage() {
      if (
        this.editEvent.category == 'CIT Meeting' ||
        this.editEvent.category == 'Executive Presentation' ||
        this.editEvent.category == 'Updates to School Teams' ||
        this.editEvent.category == 'Professional Learning'
      ) {
        return 'Registration is not available for this Category';
      } else if (
        this.editEvent.category == 'Student / School Event or Activity' ||
        this.editEvent.category == 'Job Readiness Workshop' ||
        this.editEvent.category == 'Apprenticeship and Traineeship Headstart' ||
        this.editEvent.category == 'VET Ambassador Event'
      ) {
        return 'If your audience includes Students, registration will be required for this Category';
      }

      return '';
    },
    initiativeTypes() {
      const initiativeTypesStore = useInitiativeTypesStore();
      return initiativeTypesStore.items;
    },
    filteredInitiativeTypes() {
      // Check if user is "Super Admin" and return all initiative types if true
      if (this.user && this.user.role === 'Super Admin') {
        const initiativeTypesStore = useInitiativeTypesStore();
        return initiativeTypesStore.items;
      }

      // Proceed with filtering for "Event Admin"
      const userAllowedTypes = Array.isArray(this.user.initiative_types) ? this.user.initiative_types : [];
      const initiativeTypesStore = useInitiativeTypesStore();
      return initiativeTypesStore.items.filter(type => userAllowedTypes.includes(type));
    },
    categories() {
      const categories = useCategoriesStore();
      return categories.items;
    },
    filteredCategories() {
      if (!this.editEvent.initiative_type) {
        return this.categories;
      }
      if (this.editEvent.initiative_type == 'VET Ambassadors') {
        return ['VET Ambassador Event'];
      }

      if (this.editEvent.initiative_type == 'Job Readiness') {
        return ['Job Readiness Workshop'];
      }

      if (
        this.editEvent.initiative_type ==
        'Apprenticeship and Traineeship Headstart'
      ) {
        return ['Apprenticeship and Traineeship Headstart'];
      }

      if (this.editEvent.initiative_type == 'Innovating Careers Education') {
        return [
          'CIT Meeting',
          'Executive Presentation',
          'Professional Learning',
          'Student / School Event or Activity',
          'Updates to School Teams',
        ];
      }

      return this.categories;
    },
    activityTypes() {
      const activityTypes = useActivityTypesStore();
      return activityTypes.items;
    },
    filteredActivities() {
      if (!this.editEvent.initiative_type) {
        return this.activityTypes;
      }
      if (this.editEvent.initiative_type == 'VET Ambassadors') {
        return ['Presentation Session'];
      }

      if (this.editEvent.initiative_type == 'Job Readiness') {
        return ['Workshop'];
      }

      if (
        this.editEvent.initiative_type ==
        'Apprenticeship and Traineeship Headstart'
      ) {
        return ['Course'];
      }

      return this.activityTypes;
    },
    terms() {
      const terms = useTermsStore();
      return terms.items;
    },
    formats() {
      const formats = useFormatsStore();
      return formats.items;
    },
    audiences() {
      const audiences = useAudiencesStore();
      return audiences.items;
    },
    filteredAudiences() {
      if (!this.editEvent.category) {
        return this.audiences;
      }
      if (
        this.editEvent.category == 'CIT Meeting' ||
        this.editEvent.category == 'Executive Presentation' ||
        this.editEvent.category == 'Updates to School Teams' ||
        this.editEvent.category == 'Professional Learning'
      ) {
        return this.audiences.filter(
          (audience) => audience !== 'High school students'
        );
      }

      return this.audiences;
    },
    presenterTypes() {
      const presenterTypes = usePresenterTypesStore();
      return presenterTypes.items;
    },
    loggedInUserRole() {
      const auth = useAuthStore();
      return auth.role;
    },
  },
  mounted() {
    Promise.all([
      this.$api.regions.fetch().then((results) => {
        this.regions = results.regions;
      }),

      this.$api.schools.fetchNestedData().then((results) => {
        this.schools = results;
      }),

      this.$api.schools.fetchGroups().then((results) => {
        this.schoolGroups = results.groups;
      }),

      this.$api.user.fetch().then((results) => {
        this.user = results.user;
      }),
    ]).then(() => {
      this.isMounted = true; // Set the flag to true after the component is mounted and data is loaded
      this.editEventStep = 1; // Reset step to 1 when the component is mounted
    }).catch(() => { });
  },
  methods: {
    handleDialogReturn() {
      this.openErrorDialog = false
    },
    setupGoogleMapsAutocomplete() {
      // Ensure the input exists and format is In-person before setting up autocomplete
      this.$nextTick(() => {
        if (typeof google !== 'undefined' && this.$refs.addressInput && this.editEvent.format === 'In-person') {
          const input = this.$refs.addressInput.$el.querySelector('input');
          const autocomplete = new google.maps.places.Autocomplete(input, {
            componentRestrictions: { country: 'au' },
          });

          // Listen for the place_changed event
          autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();
            if (place.formatted_address) {
              this.editEvent.venue_address = place.formatted_address;
            }
          });
        }
      });
    },
    allowedDates: (val) => parseInt(val.split('-')[2], 10) % 2 === 0,
    showErrorDialog(errorType) {
      switch (errorType) {
        case 'schools':
          this.errorDialogTitle = 'Students registered from unselected school';
          this.errorDialogMessage = 'You are attempting to remove a school which has students registered for this event. Please deregister these students to continue.';
          break;
        case 'category':
          this.errorDialogTitle = 'Students registered in this event';
          this.errorDialogMessage = 'There are already student registrations for this event. Please deregister students before changing event type.';
          break;
        case 'audience':
          this.errorDialogTitle = 'Students registered in this event';
          this.errorDialogMessage = 'You are attempting to remove High School Students as an audience type. There are already students registered for this event. Please deregister them before continuing.';
          break;
        case 'capacity':
          this.errorDialogTitle = 'Number of students registered higher than capacity';
          this.errorDialogMessage = 'You are attempting to reduce the capacity below the number of registered students. Please deregister some students to continue.';
          break;
        default:
          this.errorDialogTitle = 'Validation Error';
          this.errorDialogMessage = 'An unknown error occurred.';
          break;
      }
      this.openErrorDialog = true;
    },
    isValidStep(step) {
      let isValid = true;
      this.capacityError = '';
      this.categoryError = '';
      this.audienceError = '';
      this.schoolsError = '';
      this.showStepError = false;

      if (step === 1) {
        isValid =
          !!this.editEvent.title &&
          !!this.editEvent.initiative_type &&
          !!this.editEvent.category &&
          !!this.editEvent.activity_type &&
          !!this.editEvent.description &&
          this.validation.optionalNumber(this.editEvent.presenter_number) === true;

        if (!this.dulplicateEvent && this.event.number_of_registrations > 0) {
          const noStudentRegistrationCategories = [
            'CIT Meeting',
            'Executive Presentation',
            'Professional Learning',
            'Updates to School Teams',
          ];

          if (noStudentRegistrationCategories.includes(this.editEvent.category)) {
            isValid = false;
            this.categoryError = 'There are already student registrations for this event. Please deregister students before changing event type.';
            this.showErrorDialog('category');
          }
        }

        if (!isValid && !this.categoryError) {
          this.showStepError = true;
        }
      }

      if (step === 2) {
        isValid =
          !!this.editEvent.start_date &&
          !!this.editEvent.end_date &&
          !!this.editEvent.start_time &&
          !!this.editEvent.end_time &&
          !!this.editEvent.term &&
          this.validation.number(this.editEvent.capacity) === true &&
          !!this.editEvent.format &&
          (this.editEvent.format === 'In-person'
            ? !!this.editEvent.venue_address
            : !!this.editEvent.access_link && this.validation.url(this.editEvent.access_link) === true);

        if (!this.dulplicateEvent && this.event.number_of_registrations > this.editEvent.capacity) {
          isValid = false;
          this.capacityError = 'You are attempting to reduce the capacity below the number of registered students. Please deregister some students to continue.';
          this.showErrorDialog('capacity');
        }

        if (!isValid && !this.capacityError) {
          this.showStepError = true;
        }
      }

      if (step === 3) {
        isValid =
          !!this.editEvent.school_type &&
          Array.isArray(this.editEvent.schools) &&
          this.editEvent.schools.length > 0 &&
          Array.isArray(this.editEvent.audiences) &&
          this.editEvent.audiences.length > 0;

        if (!this.dulplicateEvent && this.event.number_of_registrations > 0) {
          if (!this.editEvent.audiences.includes('High school students')) {
            isValid = false;
            this.audienceError = 'There are already student registrations for this event. Please deregister students before changing audience.';
            this.showErrorDialog('audience');
          }

          const missingSchools = this.event.school_ids_with_students_registered.filter(
            (schoolId) => !this.editEvent.schools.includes(schoolId)
          );
          if (missingSchools.length > 0) {
            isValid = false;
            this.schoolsError = 'You are attempting to remove a school which has students registered for this event. Please deregister these students to continue.';
            this.showErrorDialog('schools');
          }
        }

        if (!isValid && !this.audienceError && !this.schoolsError) {
          this.showStepError = true;
        }
      }

      return isValid;
    },
    moveToStep(targetStep) {
      this.showStepError = false;
      if (this.isValidStep(this.editEventStep)) {
        this.editEventStep = targetStep;
      } else {
        this.showStepError = true;
      }
    },
    filterHighSchoolStudents() {
      const unsupportedCategories = [
        'CIT Meeting',
        'Executive Presentation',
        'Updates to School Teams',
        'Professional Learning'
      ];

      if (unsupportedCategories.includes(this.editEvent.category)) {
        this.editEvent.audiences = this.editEvent.audiences.filter(
          audience => audience !== 'High school students'
        );
      }
    },
    saveEventChanges() {
      this.showStepError = false;
      this.categoryError = '';
      this.audienceError = '';
      this.schoolsError = '';

      if (this.isValidStep(3)) { // Assuming step 3 is the last step and saving happens there
        const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        const startInUserTimezone = `${this.editEvent.start_date}T${this.editEvent.start_time}:00`;
        const endInUserTimezone = `${this.editEvent.end_date}T${this.editEvent.end_time}:00`;

        // Convert user's time to UTC using date-fns-tz
        this.editEvent.start_datetime = zonedTimeToUtc(
          startInUserTimezone,
          userTimezone
        ).toISOString();
        this.editEvent.end_datetime = zonedTimeToUtc(
          endInUserTimezone,
          userTimezone
        ).toISOString();

        // Filter out "High school students" if necessary
        this.filterHighSchoolStudents();

        // Set id to null if we want to duplicate event
        const id = this.dulplicateEvent ? null : this.editEvent.id;

        this.$api.events
          .save(id, this.editEvent)
          .then((response) => {
            return (this.editEvent.id = response.id);
          })
          .then(() => {
            const successMessage = this.dulplicateEvent
              ? `Your event ${this.editEvent.title} has been successfully created`
              : `Your event ${this.editEvent.title} has been successfully updated`;
            this.$emit('showEditSuccessMessage', successMessage);
            this.toggleDialog(true);
          });
      } else {
        this.showStepError = true;
      }
    },
    toggleDialog(changed) {
      this.$emit(
        'eventEdited',
        this.editEvent.title,
        this.dulplicateEvent,
        changed
      );
      this.formKey += 1;
    },
    resetForm() {
      this.$refs.editEventForm.reset();
      this.editEvent = {};
      this.editEventStep = 1; // Reset step to 1 when form is reset
    },
  },
};
</script>

<style scoped>
.custom-dialog .v-dialog__content {
  z-index: 2000 !important;
  /* Ensure this value is higher than the fullscreen dialog */
}
</style>